import React from 'react';

function Footer()
{
    return(
        <footer>
            <div className="container-fluid">
                <div className="wave">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
                    </svg>
                </div>
                <div className='social'>
                    <a href="https://github.com/sarahlochhead" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-github fa-2x p-3"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/sarah-lochhead/" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-linkedin fa-2x p-3"></i>
                    </a>
                </div>
                <div>
                    <small>All Rights Reserved. Created by Sarah Lochhead © 2023</small>
                </div> 
            </div>  
        </footer>
    );

}
export default Footer;