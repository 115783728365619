import React, { useRef, FormEvent, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export const Contact = () => {
  const form = useRef<HTMLFormElement | null>(null);

  const toastifySuccess = () => {
    toast.success('Message Sent', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
  };

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
            process.env.REACT_APP_SERVICE_ID || '',
            process.env.REACT_APP_TEMPLATE_ID || '',
            form.current,
            process.env.REACT_APP_PUBLIC_KEY || ''
        )
        .then(
            (result) => {
            console.log(result.text);
            toastifySuccess();
            },
            (error) => {
            console.log(error.text);
            },    
        )  
        form.current?.reset(); 
    }
  };

  useEffect(()=>{
    document.title = "Sarah Lochhead - Contact";
    }, []);

  return (    
    <main className="container-fluid" id="contact">
        <div className="container">
            <section>
                <h1>Contact</h1>
            </section>

            <form ref={form} onSubmit={sendEmail}>
                <div className="mb-3 col-lg-6">
                    <label>Full Name</label>
                    <input type="text" className="form-control" name="name" placeholder="Enter your full name" aria-describedby="Full Name Input" />
            </div>
            <div className="mb-3 col-lg-6">
                    <label className="form-label">Email Address</label>
                    <input type="email" className="form-control" id="emailAddress" name="email" placeholder="Enter your email address" aria-describedby="Email Address Input"  />
                </div>
                <div className="mb-3 col-lg-6">
                    <label htmlFor="subject" className="form-label">Subject</label>
                    <input type="text" className="form-control" id="subject" name="subject" placeholder="subject" aria-describedby="Subject" />
                </div>
                <div className="mb-3 col-lg-6">
                    <label htmlFor="message" className="form-label">Message</label>
                    <textarea className="form-control" id="message" name="message" aria-describedby="Message Input Textarea" placeholder="Enter your message" ></textarea>
                </div>
                <div className="col-6 d-flex justify-content-between flex-row-reverse ">
                    <button type="submit" id="submitButton" className="btn"><i className="fa-solid fa-paper-plane"></i> Send</button>
                    <button type="reset" id="resetButton" className="btn btn-2"><i className="fa-solid fa-undo"></i> Reset </button>
                    </div>
                </form>
                <ToastContainer
                    transition={Slide}
                />
            </div>
    </main>
  );

};

export default Contact;