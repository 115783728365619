import React, { useEffect } from 'react';

function Services()
{
    useEffect(()=>{
        document.title = "Sarah Lochhead - Services";

    }, []);

    return(
        <main className="container-fluid" id="services">
            <div className="container">
                <h1>Services</h1>
                <div className="row">
                    <section className="col-lg col-md-6, col-sm-12">
                        <h2><i className="fa-solid fa-code"></i> Web Design and Development</h2>
                        <p>Designing and deploying your business website or web application including commerce solutions and payment gateways.</p>
                    </section>
                    <section className="col-lg col-md-6, col-sm-12">
                        <h2><i className="fa-solid fa-palette"></i> Brand Development</h2>
                        <p>Combing fonts, colours, logos and your brand values to deliver a clear and consistent brand across your business activities. Includes the creation of a comprehensive style guide.</p>
                    </section>
                </div>

                <div className="row">
                    <section className="col-lg col-md-6, col-sm-12"> 
                        <h2><i className="fa-solid fa-hand-holding-heart"></i> Arts and Non-Profit Digital Strategy Development</h2>
                        <p>Generating a systems and digital needs assessment for internal and external systems to assist with process automation and streamlining of administrative and marketing pipelines.</p>
                       
                    </section>
                    <section className="col-lg col-md-6, col-sm-12">
                        <h2><i className="fa-solid fa-comments-dollar"></i> Digital Marketing Strategy</h2>
                        <p>Developing an easy to follow and small team manageable digital marketing strategy to get your business in front of the right audiences.</p>
                    </section>
                </div>

                <div className="row">
                    <section className="col-lg col-md-6, col-sm-12">
                        <h2><i className="fa-solid fa-universal-access"></i> Accessibility Assistance</h2>
                        <p>Conducting an assessment of your website with respect to accessibility standards. From crafting alternative text for images and reviewing colour contrast, to reviewing how your website sounds, improve the reach of your site to all potential audiences.</p>
                    </section>
                    <section className="col-lg col-md-6, col-sm-12">
                        <h2><i className="fa-solid fa-pen"></i> Content Writing</h2>
                        <p>Crafting digital copy in your brand's voice. Preparing high-quality blog posts, social posts, email newsletter content, and site copy all with SEO in mind. </p>
                    </section>
                </div>
            </div>
        </main>
    )

}

export default Services;