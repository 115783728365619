import React, { useEffect } from 'react';
import headshot from '../../Assets/images/sarah-lochhead-headshot.jpg';

function About()
{

    useEffect(()=>{
        document.title = "Sarah Lochhead - About";

    }, []);

    return(
            <main className="container-fluid" id="about">                       
                <div className="container">
                    <h1>About</h1>
                    <div className="row">
                        <div className="col-xl col-lg col-md-12 headshot">
                            <img src={headshot} alt="Sarah Lochhead headshot" />
                        </div>
                        <div className="col-xl col-lg col-md-12">
                            <p> Hello! Sarah here.</p>
                            <p>I'm a creative problem-solver committed to relationship building, community engagement and education. I work with a variety of coding languages and pride myself on being research-driven. I'm also a seasoned not-for-profit administrator with experience in the performing arts, publishing, and with support organizations. 
                            </p>
                            <p>I have a BFA and an MA focused in Dance from York University. This offers me a unique perspective on web development — one that centers the user experience while remaining business oriented and playful. 
                            </p>
                            <p>As a Tech Steward, my work is centered on technology as a force of good in the world. I'm passionate about accessibility, digital literacy, relationship building, and creating community through my work. 
                            </p>
                            <p>I have a diploma from Georgian College from the Interactive Media Design Web program and I've completed coding and digital marketing programs at Juno College, Lighthouse Labs, and Camp Tech.
                            </p>
                        </div>
                    </div>  
                    <div className="row">
                        {/* <div className="col">
                                <Link to={"#""}>
                                    <button className="btn">View Resume</button> 
                                </Link>
                            </div>
                            <div className="col">
                            <Link to={"/portfolio"}>
                                <button className="btn">View Portfolio</button> 
                            </Link>
                            
                            </div> */}
                    </div>  
                </div>
        </main>
    )
}

export default About;