import React from 'react';
import { Link } from 'react-router-dom';


function Home()
{
    return(
        <main className="container-fluid" id="home">                        
        <h1 className="visually-hidden">Home</h1>
            <div className="container">
                <section className='home-content'>
                    <h2>Breathing <span>Creativity</span> Into Your Brand</h2>
                    <div>        
                        <p>You deserve digital solutions as unique as you are.</p> 

                        <p>Let's create experiences that center user needs and convey your mission and values through embodied design.</p>
                        <div>
                            <Link to={"/about"}>
                                <button className="btn btn-2">About Sarah</button>
                            </Link>
                            
                            <Link to={"/services"}>
                                <button className="btn">Explore Services</button>
                            </Link>  
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}

export default Home;