import React, { useEffect } from 'react';

function Portfolio()
{

    useEffect(()=>{
        document.title = "Sarah Lochhead - Portfolio";

    }, []);

    return(
        <main className="container-fluid" id="portfolio">                        
            <div className="container">
                <h1>Portfolio</h1>
                <section>
                    <h2>Coming Soon ...</h2>
                </section>
            </div>
        </main>
    )
}

export default Portfolio;